import React from "react";
import Footer from "../footer/Footer";
import AgroStories from "./AgroStories";
import Banner from "./Banner";
import Dashboard from "./Dashboard";
import DevelopersCarousel from "./DevelopersCarousel";
import ExploreNearby from "./ExploreNearby";
import OpportunitiesCarousel from "./OpportunitiesCarousel";
import OurExperts from "./OurExperts";
import OurMission from "./OurMission";

export default function Home() {
  return (
    <div>
     {/* <Banner />
       <ExploreNearby />
      <OurMission />
      <OpportunitiesCarousel />
      <AgroStories />
      <DevelopersCarousel />
      <OurExperts />
      <Footer /> */}
      <Dashboard />

    </div>
  );
}
