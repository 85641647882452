import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { CardMedia } from "@mui/material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Education Detail image",
    imgPath:
      "https://saaehapp.com/static/media/EducationDetailCarouselImage.d3d78f47.webp",
  },
  {
    label: "Education Detail image",
    imgPath:
      "https://saaehapp.com/static/media/EducationDetailCarouselImage.d3d78f47.webp",
  },
  {
    label: "Education Detail image",
    imgPath:
      "https://saaehapp.com/static/media/EducationDetailCarouselImage.d3d78f47.webp",
  },
  {
    label: "Education Detail image",
    imgPath:
      "https://saaehapp.com/static/media/EducationDetailCarouselImage.d3d78f47.webp",
  },
];
export default function DetailBanner({image}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Box sx={{ maxWidth: 750, flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
        <CardMedia
          component="img"
          sx={{
            display: "block",
            overflow: "hidden",
            width: "100%",
            height: "275px",
            borderRadius: 4,
          }}
          src={image}
          alt={image}
        />
        </AutoPlaySwipeableViews>
      </Box>
    </div>
  );
}
