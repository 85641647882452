import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import axios from "../utils/Axios"; //import axios config
//import Masonry from '@mui/lab/Masonry'; 
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Box from '@mui/material/Box';
import LoadingOverlay from "react-loading-overlay";

export default function DetailAccordionGallery({saaheId}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };


  const [loading, setLoading] = React.useState(false);
  const [institution, setInstitution] = React.useState([]);

  React.useEffect(() => {
    getGallery(saaheId);
  }, []);


  const getGallery = (saaheId) => {
    setLoading(true);
    axios(`Saahe/get_list_gallery?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setInstitution(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div>
        {isViewerOpen && (
          <ImageViewer
            src={institution}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}

    <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
    <Box>
      <Masonry columnsCount={3}>
        {institution && institution.map((item, index) => (
          <div key={index}>
              <Box sx={{m: 0.5}}>
            <img
              src={`${item}?w=162&auto=format`}
              srcSet={`${item}?w=162&auto=format&dpr=2 2x`}
              alt="rr"
              loading="lazy"
              image={item}
              onClick={() => openImageViewer(index)}
              key={index}
              style={{
                borderRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
            </Box>
          </div>
        ))}
      </Masonry>
    </Box>
    </LoadingOverlay>

      </div>
    </div>
  );
}