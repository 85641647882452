import React, { useState } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Divider,Grid,Box,Paper,Typography,ListItem,IconButton} from "@mui/material";
import axios from "../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import image2 from "../../images/logo.webp";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import DatePicker from "@mui/lab/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function MyAccountEditNewsAndEventsDialogEdit({news_id,saahe_id,getNews}) {
  const [status, setStatus] = React.useState("");

  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const alert = useAlert();
  const [value, setValue] = React.useState(new Date());
  const [image, setImage] = React.useState("");
  const [edit, setedits] = React.useState({});

  const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    axios(`Saahe/get_news_info?id=${news_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          setedits(res.data.data[0]);
          setValue(res.data.data[0].awd_date);
          setUploadImage(res.data.data[0].images);
          setStatus(res.data.data[0].status);
        }
      })
      .catch((err) => console.log(err));

  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("news_id", news_id);
    formdata.append("title", edit.title);
    formdata.append("logo", image);
    formdata.append("newsDescription", edit.description);
    //formdata.append("event_date", value);
    formdata.append("status", status);
    axios(`Saahe/update_news`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getNews(news_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <ListItem
        onClick={handleClickOpen}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        }
      ></ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit News and Events</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                placeholder="News and Events Name"
                variant="outlined"
                value={edit.title}
                onChange={(e) => {
                  setedits({ ...edit, title: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="DatePicker"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                placeholder="Description of the News and Events"
                multiline
                rows={4}
                value={edit.description}
                onChange={(e) => {
                  setedits({ ...edit, description: e.target.value });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Paper style={{ width: "250px" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">
                    News and Events Thumbnail{" "}
                  </Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
