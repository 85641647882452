import {
  Card,
  createTheme,
  ThemeProvider,
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
//import { Link, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Header from "../header/Header";
import logo1 from "../images/logo-icon.webp";
import logo_aqar from "../images/logo_aqar.png";
import banner4 from "../images/Banner4.webp";
import banner3 from "../images/Banner3.webp";
import logo2 from "../images/MyAccount/ManageListingLogo1.webp";
import logo3 from "../images/MyAccount/ManageListingLogo2.webp";
import logo4 from "../images/MyAccount/ManageListingLogo3.webp";
import logo5 from "../images/MyAccount/ManageListingLogo4.webp";
import logo6 from "../images/MyAccount/ManageListingLogo5.webp";
import logo7 from "../images/MyAccount/ManageListingLogo6.webp";
import izrabanner from "../images/HomeBanner.webp";
import amalbanner from "../images/bannerAmal.jpg";
import isnabanner from "../images/isnabanner3.webp";
import saaehBanner from "../images/saaehBanner.webp";
import waselbanner from "../images/waselbanner2.webp";
import amusementbanner from "../images/amusementbanner.webp";
import sustainabilityBanner from "../images/sustainabilityBanner.webp";
import educationBanner from "../images/education_detail_banner2.png";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#1e3c55",
    },
    secondary: {
      main: "#676767",
    },
    linkcolor: {
      main: "#013A4B",
    },
  },
});
export default function Dashboard() {
  const classes = useStyles();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CardMedia
          image={saaehBanner}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            minHeight: "100vh",
            bottom: 0,
            top: 0,
            position: "revert",
          }}
         
       
        >
        
            <Grid container spacing={3} my={5}>
              <Container>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://taleem.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={educationBanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo2}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore TA`LEEM">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore TA`LEEM
                                  </Typography>
                                </Tooltip>
                                {/* <Typography
                              variant="caption"
                              color="text.secondary"
                              className="text_bg"
                            >
                              Find unique education centers
                            </Typography> */}
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://medical.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={banner4}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo1}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Medical Tourism">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Medical Tourism
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://aqar.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={banner3}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo3}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Aqar">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Aqar
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://izra.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={izrabanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo4}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Izra'a">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Izra'a
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://amal.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={amalbanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo5}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Amal">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Amal
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://isna.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={isnabanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo6}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore ISNA'A">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore ISNA'A
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://wasel.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={waselbanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo7}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Wasel">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Wasel
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link target="_blank" href="https://amusement.saaehapp.com/">
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={amusementbanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo1}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Amusement">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Amusement
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item xs={2} className="home_grid">
                    <Link
                      target="_blank"
                      href="https://sustainability.saaehapp.com/"
                    >
                      <Box
                        className="TopUniversities_box"
                        p={2}
                        //     onClick={() => SaaheView(value.id, value.type)}
                      >
                        <Card className={classes.TopUniversitiesCard}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="100"
                              image={sustainabilityBanner}
                              alt="title"
                            />

                            <Box className="cp">
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to="/">
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={logo1}
                                      alt=""
                                      to=""
                                    />
                                  </Link>
                                </Box>

                                <Tooltip Tooltip title="Explore Sustainability">
                                  <Typography
                                    Tooltip
                                    title="title"
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    Explore Sustainability
                                  </Typography>
                                </Tooltip>
                              </CardContent>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
       
        </CardMedia>
      </ThemeProvider>
    </div>
  );
}
